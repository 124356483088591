// ---- Font sizes ---- \\
:root {
	--font-size-base:			18px;

	// Above normal scale (anything absurdly big) based on SI prefixes
	--font-size-peta:			90px;
	--font-size-tera:			80px;
	--font-size-giga:			70px;
	--font-size-mega:			46px;

	// Normal scale (h1-h6)
	--font-size-h1:				32px;
	--font-size-h2:				28px;
	--font-size-h3:				24px;
	--font-size-h4:				22px;
	--font-size-h5:				19px;
	--font-size-h6:				18px;


	// Below normal scale - based on SI prefixes
	--font-size-milli:     		14px;
	--font-size-micro:     		13px;
	--font-size-nano:      		12px;
	--font-size-pico:      		11px;
	--font-size-femto:     		10px;


	// Special sizes
	--font-size-label:			14px;
	--font-size-lead:			20px;
	--font-size-small:			14px;
	--font-size-blockquote:		30px;
	--font-size-section:		14px;

	--font-size-card:			12px;
	--font-size-card-title:		16px;
}



// ---- Font Weights ---- \\
:root {
	--font-weight-base:			300;
	--font-weight-light:		200;
	--font-weight-normal:		400;
	--font-weight-semibold:		500;
	--font-weight-bold:			700;
}



//---- Line Heights ---- \\
:root {
	// Typically line-height of paragraphs
	--line-height-base:			1.4;

	--line-height-heading:		1.1;
	--line-height-lead:			1.2;
	--line-height-list:			1.7;
}


// ---- Ionic overrides ---- \\
:root {
	--ion-font-family: 'Fira Sans';
}