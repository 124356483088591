
/* latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(/assets/fonts/FiraSans-Regular.woff2) format('woff2');
}

/* latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(/assets/fonts/FiraSans-Medium.woff2) format('woff2');
}

/* latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(/assets/fonts/FiraSans-Bold.woff2) format('woff2');
}
