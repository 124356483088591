
// General inputs

form {

    ion-item {
        //border: 1px solid var(--default-color-border); 
        //--box-shadow: var(--default-box-inside-shadow);
    }

    ion-label {
        font-weight: bold;
        text-transform: uppercase;
    }

}

ion-datetime {
    --background: none;
    text-transform: uppercase;

    ion-button {
        text-transform: none;
    }
}