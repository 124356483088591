
// General buttons

ion-button {
    --border-radius: 0;
    --box-shadow: var(--default-box-shadow);
    text-transform: uppercase;
    font-size: var(--font-size-milli);


    &[strong=true] {
        font-weight: var(--font-weight-semibold);

        ion-label {
            font-weight: var(--font-weight-semibold);
        }
    }

    &[size=large] {
        height: var(--spacing-double);
    }
}