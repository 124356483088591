// Base Spacing Unit
$spacing: 26px;



// Klean variables
:root {
	--spacing: #{$spacing};
	--spacing-half: #{$spacing / 2};
	--spacing-quarter: #{$spacing / 4};

	--spacing-double: #{$spacing * 2};
}



// Ion overrides
:root {
	--ion-padding: #{$spacing / 2};
}