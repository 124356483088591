
:root {
    // ---- Colors ---- \\
    --color-white: #fff;
    --color-black: #000;


    // ---- Borders ---- \\
	--default-color-border: #dedede;


    // ---- Shadow and effects ---- \\
	--default-box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
    --default-box-inside-shadow: inset rgba(0, 0, 0, 0.3) 0px 0px 10px;


    // ---- Gradients effects ---- \\
	--primary-gradient-overlay: linear-gradient(0deg, rgba(135,17,26,0.95) 0%, rgba(195,23,37,0.8) 50%, rgba(135,17,26,0.95) 100%);


    // ---- Opacity ---- \\
    --element-muted-opacity: .5;
}